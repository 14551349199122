<template>
  <Section>

    <Container>
      <FadeIn class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-section-title">{{ content.title }}</h2>
        <p class="text-subtitle">{{ content.subtitle }}</p>
      </FadeIn>

      <div class="mt-12">
        <FadeIn class="md:grid md:gap-6 lg:gap-8 md:grid-cols-3">
          <div 
          v-for="(stat, index) in content.Stat"
          :key="'b-cards-link-'+index" 
          class="mt-6 md:mt-6 h-full"
          >
            <div 
            class="flow-root rounded-xl p-6 h-full"
            :class="colorArray[index]"
            >
              <div class="">
                <div 
                class="font-bold tracking-tight text-gray-900"
                :class="[
                  stat.title.length > 12 ? 'text-3xl' : 'text-5xl',
                ]"
                >
                  {{ stat.title }}
                </div>
                <div class="mt-2 text-2xl font-bold tracking-tight text-gray-900 xl:text-3xl">{{ stat.subtitle }}<a v-if="stat.sourceUrl" :href="stat.sourceUrl" target="_blank" class="ml-1 relative -top-[6px]" aria-label="Click here to view the source of the statistic"><Icon name="iconoir:link" class="h-4 w-4" /></a></div>
                <div class="mt-4">{{ stat.content }}</div>
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    </Container>

  </Section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const colorArray = [
  'bg-[#FFD66B]',
  'bg-[#FF8AAC]',
  'bg-[#FF7E54]'
]
</script>